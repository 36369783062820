

/* === START WORDS === */

.word {
    margin: auto;
    margin-top: 125 px;
}
/* 
.Z,
.A,
.C,
.H {
	top: 0;
} */

/* .Z {
	-webkit-animation: z-fall 1s ease;
	-moz-animation: z-fall 1s ease;
	animation: z-fall 1s ease;
}

.A {
	-webkit-animation: a-fall 1.5s ease;
	-moz-animation: a-fall 2s ease;
	animation: a-fall 2s ease;
}

.C {
	-webkit-animation: c-fall 2s ease;
	-moz-animation: c-fall 3s ease;
	animation: c-fall 3s ease;
}

.H {
	-webkit-animation: h-fall 2.5s ease;
	-moz-animation: h-fall 4s ease;
	animation: h-fall 4s ease;
} */

.z-top {
	position: relative;
	background: #333D47;
	height: 25px;
	width: 100px;
}

.z-top:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 50px;
	width: 25px;
	top: 25px;
	left: 75px;
}

.z-top:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 25px;
	top: 75px;
	left: 50px;
}

.z-bottom {
	position: relative;
	background: #333D47;
	height: 25px;
	width: 100px;
	top: 150px;
}

.z-bottom:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 50px;
	width: 25px;
	top: -50px;
}

.z-bottom:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 25px;
	top: -75px;
	left: 25px;
}

.a-top {
	position: relative;
	background: #333D47;
	height: 125px;
	width: 25px;
	left: 125px;
	top: 25px;
}

.a-top:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 50px;
	width: 25px;
	left: 25px;
	top: -50px;
}

.a-top:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 25px;
	left: 50px;
	top: -75px;
}

.a-bottom {
	position: relative;
	background: #333D47;
	height: 125px;
	width: 25px;
	left: 225px;
	top: -100px;
}

.a-bottom:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 50px;
	width: 25px;
	left: -25px;
	top: -50px;
}

.a-bottom:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 75px;
	left: -75px;
	top: 25px;
}

.c-top {
	position: relative;
	background: #333D47;
	height: 150px;
	width: 25px;
	top: -275px;
	left: 275px;
}

.c-top:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 75px;
	top: -25px;
	left: 25px;
}

.c-top:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 25px;
	left: 100px;
}

.c-bottom {
	position: relative;
	background: #333D47;
	height: 25px;
	width: 75px;
	left: 300px;
	top: -275px;
}

.c-bottom:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 25px;
	top: -25px;
	left: 75px;
}

.h-all {
	position: relative;
	background: #333D47;
	height: 200px; 
	width: 25px;
	left: 425px;
	top: -475px;
}

.h-all:before {
	content: "";
	position: absolute;
	background: #333D47;
	height: 25px;
	width: 75px;
	left: 25px;
	top: 100px;
}

.h-all:after {
	content: "";
	position: absolute;
	background: #333D47;
	height: 200px;
	width: 25px;
	left: 100px;
}

/* === START PERSON === */

.zach {
	position: relative;
	top: -650px;
	left: 675px;
}

.head {
	position: relative;
	background: #FFF2BA;
	height: 75px;
	width: 75px;
	top: 0;
	-webkit-animation: head-fall 4.85s ease;
	-moz-animation: head-fall 4.85s ease;
	animation: head-fall 4.85s ease;
}

.head:before {
	content: "";
	position: absolute;
	background: #9F805E;
	height: 25px;
	width: 100px;
	top: -25px;
	left: -25px;
}

.head:after {
	content: "";
	position: absolute;
	background: #9F805E;
	height: 100px;
	width: 25px;
	top: -25px;
	left: -25px;
}

.shirt {
	position: relative;
	background: #7D9BA7;
	height: 125px;
	width: 125px;
	top: 25px;
	left: -25px;
	-webkit-animation: shirt-fall 4.65s ease;
	-moz-animation: shirt-fall 4.65s ease;
	animation: shirt-fall 4.65s ease;
}

.shirt:before {
	content: "";
	position: absolute;
	background: #7D9BA7;
	height: 25px;
	width: 75px;
	top: -25px;
	left: 25px;
}

.shirt:after {
	content: "";
	position: absolute;
	background: #7D9BA7;
	height: 50px;
	width: 175px;
	left: -25px;
	top: 25px;
}

.hands {
	position: relative;
	background: #FFF2BA;
	height: 25px;
	width: 25px;
	top: -25px;
	left: -50px;
	-webkit-animation: hands-fall 4.5s ease;
	-moz-animation: hands-fall 4.5s ease;
	animation: hands-fall 4.5s ease;
}

.hands:after {
	content: "";
	position: absolute;
	background: #FFF2BA;
	height: 25px;
	width: 25px;
	left: 150px;
}

.pants {
	position: relative;
	background: #028BBA;
	height: 25px;
	width: 125px;
	left: -25px;
	top: 0;
	-webkit-animation: pants-fall 4.25s ease;
	-moz-animation: pants-fall 4.25s ease;
	animation: pants-fall 4.25s ease;

}

.pants:before {
	content: "";
	position: absolute;
	background: #028BBA;
	height: 75px;
	width: 50px;
	top: 25px;
}

.pants:after {
	content: "";
	position: absolute;
	background: #028BBA;
	height: 75px;
	width: 50px;
	top: 25px;
	left: 75px;
}

.shoes {
	position: relative;
	background: #D0021B;
	height: 25px;
	width: 75px;
	top: 75px;
	left: -50px;
	-webkit-animation: shoes-fall 4s ease;
	-moz-animation: shoes-fall 4s ease;
	animation: shoes-fall 4s ease;
}

.shoes:after {
	content: "";
	position: absolute;
	background: #D0021B;
	height: 25px;
	width: 75px;
	left: 100px;
}

.blink {
	position: relative;
	-webkit-animation: blink 0.75s infinite ease;
	-moz-animation: blink 1s infinite ease;
	animation: blink 1s infinite ease;
}

.cursor {
	position: relative;
	background: #E90B8B;
	height: 25px;
	width: 100px;
	top: -500px;
	left: 625px;
	-webkit-animation: to-right 3.75s ease;
	-moz-animation: to-right 3.75s ease;
	animation: to-right 3.75s ease;

}

/* ============== 
	ANIMATIONS
============== */	

@-webkit-keyframes z-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes z-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes z-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes a-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	50% {
		opacity: 0;
		top: -500px;
	}
	
	87.5% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes a-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	50% {
		opacity: 0;
		top: -500px;
	}
	
	87.5% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes a-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	50% {
		opacity: 0;
		top: -500px;
	}
	
	87.5% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes c-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	66.67% {
		opacity: 0;
		top: -500px;
	}
	
	91.67% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes c-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	66.67% {
		opacity: 0;
		top: -500px;
	}
	
	91.67% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes c-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	66.67% {
		opacity: 0;
		top: -500px;
	}
	
	91.67% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}
	

@-webkit-keyframes h-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes h-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes h-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes shoes-fall {
	0% {
		opacity: 0;
		top: -425px;
	}
	
	75% {
		opacity: 0;
		top: -425px;
	}
	
	93.75% {
		top: 85px;
	}
	
	100% {
		opacity: 1;
		top: 75px;
	}
}

@-moz-keyframes shoes-fall {
	0% {
		opacity: 0;
		top: -425px;
	}
	
	75% {
		opacity: 0;
		top: -425px;
	}
	
	93.75% {
		top: 85px;
	}
	
	100% {
		opacity: 1;
		top: 75px;
	}
}

@keyframes shoes-fall {
	0% {
		opacity: 0;
		top: -425px;
	}
	
	75% {
		opacity: 0;
		top: -425px;
	}
	
	93.75% {
		top: 85px;
	}
	
	100% {
		opacity: 1;
		top: 75px;
	}
}

@-webkit-keyframes pants-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes pants-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes pants-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	93.75% {
		top: 10px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes shirt-fall {
	0% {
		opacity: 0;
		top: -475px;
	}
	
	75% {
		opacity: 0;
		top: -475px;
	}
	
	100% {
		opacity: 1;
		top: 25px;
	}
}

@-moz-keyframes shirt-fall {
	0% {
		opacity: 0;
		top: -475px;
	}
	
	75% {
		opacity: 0;
		top: -475px;
	}
	
	100% {
		opacity: 1;
		top: 25px;
	}
}

@keyframes shirt-fall {
	0% {
		opacity: 0;
		top: -475px;
	}
	
	75% {
		opacity: 0;
		top: -475px;
	}
	
	100% {
		opacity: 1;
		top: 25px;
	}
}

@-webkit-keyframes hands-fall {
	0% {
		opacity: 0;
		top: -525px;
	}
	
	75% {
		opacity: 0;
		top: -525px;
	}
	
	100% {
		opacity: 1;
		top: -25px;
	}
}

@-moz-keyframes hands-fall {
	0% {
		opacity: 0;
		top: -525px;
	}
	
	75% {
		opacity: 0;
		top: -525px;
	}
	
	100% {
		opacity: 1;
		top: -25px;
	}
}

@keyframes hands-fall {
	0% {
		opacity: 0;
		top: -525px;
	}
	
	75% {
		opacity: 0;
		top: -525px;
	}
	
	100% {
		opacity: 1;
		top: -25px;
	}
}

@-webkit-keyframes head-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-moz-keyframes head-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes head-fall {
	0% {
		opacity: 0;
		top: -500px;
	}
	
	75% {
		opacity: 0;
		top: -500px;
	}
	
	100% {
		opacity: 1;
		top: 0;
	}
}

@-webkit-keyframes appear {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@-moz-keyframes appear {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes to-right {
	0% {
		left: 0;
	}
	
	20% {
		left: 0;
	}
	
	38% {
		left: 125px;
	}
	
	52% {
		left: 275px;
	}
	
	70% {
		left: 425px;
	}
	
	80% {
		left: 425px;
	}
	
	100% {
		left: 625px;
	}
}

@-moz-keyframes to-right {
	0% {
		left: 0;
	}
	
	20% {
		left: 0;
	}
	
	38% {
		left: 125px;
	}
	
	52% {
		left: 275px;
	}
	
	70% {
		left: 425px;
	}
	
	80% {
		left: 425px;
	}
	
	100% {
		left: 625px;
	}
}


@keyframes to-right {
	0% {
		left: 0;
	}
	
	20% {
		left: 0;
	}
	
	38% {
		left: 125px;
	}
	
	52% {
		left: 275px;
	}
	
	70% {
		left: 425px;
	}
	
	80% {
		left: 425px;
	}
	
	100% {
		left: 625px;
	}
}

@-webkit-keyframes blink {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@-moz-keyframes blink {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	
	50% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

