.post{
	margin-bottom: 5%;
	font-family: 'Inter', sans-serif;
	text-align: justify;
	font-size: 14px;
}
.post a{
	text-decoration: none;
	color: #18499c;
}
.post img{
	width: 100%;
}
.post-title{
	font-family: 'Poiret One', cursive;
}
.post-meta{
	font-family: 'Inter', sans-serif;
	font-weight: 300;
	font-size: 12px;
}
.post-cat{
	font-family: 'Inter', sans-serif;
	font-weight: 300;
	font-size: 12px;
	color: #1f314f;
}
.post-desc{
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}