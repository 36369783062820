.sidebar h5{
	font-family: 'Poiret One', cursive;
	border-bottom: 1px solid #000000;
}
.sidebar-about{
	padding: 5%;
	background-color: #f0f0f0;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	text-align: justify;
}
.sidebar-picture{
	width: 50%;
	border-radius: 50%;
}
.sidebar-categories{
	padding: 5%;
	list-style: none;
	font-family: 'Inter', sans-serif;
	font-weight: 300;
}
.sidebar-categories li{
	display: inline-block;
	width: 100%;
	font-size: 12px;
	color: #1f314f;
}
.sidebar-social{
	padding: 5%;
	list-style: none;
}
.sidebar-social li{
	display: inline-block;
	width: 33%;
	font-size: 20px;
}
.sidebar-social a{
	color: #1f314f;
}
.sidebar-social a:hover{
	color: #1f314f;
}
