.write img{
	width: 100%;
}
.write-icon{
	font-size: 30px;
	color: #7a7a7a;
}
.write-input{
	width: 100%;
	font-size: 30px;
	border: none;
	color: #7a7a7a;
	font-family: 'Inter', sans-serif;
}
.write-input:focus{
	outline: none;
}
.write-cat{
	font-family: 'Inter', sans-serif;
	font-weight: 300;
	font-size: 16px;
	border-bottom: solid 1PX #7a7a7a;
}
.write-text{
	font-family: 'Inter', sans-serif;
	font-size: 16px;
}
.ck-editor{
	width: 100% !important;
}