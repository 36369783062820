.header{
	border-bottom: solid 1px #f0f0f0;
}
.header h1{
	font-family: 'Poiret One', cursive;
	font-size: 48px;
}
.header p{
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	color: #7a7a7a;
}