.login{
	font-family: 'Inter', sans-serif;
}
.login h2{
	font-family: 'Poiret One', cursive;
}
.login p {
	color: #ff0000;
	font-size: 14px;
}
.login-form{
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: auto;
}
.btn{
	background-color: #212529;
	color: #fff;
}
.btn:hover{
	color: #fff;
}