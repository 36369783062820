.footer{
	position: absolute;
	bottom: 0px;
	width: 100%;
	color: #7a7a7a;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
}
.footer h5{
	color: #ffffff;
	font-family: 'Poiret One', cursive;
}
.footer a{
	color: #7a7a7a;
}
.footer a:link{
	text-decoration: none;
}
.footer a:hover{
	text-decoration: none;
	color: #7a7a7a;
}
.copyright{
	color: #ffffff;
	font-size: 12px;
}
.footer-icon{
	font-size: 22px;
}