.settings{
	font-family: 'Inter', sans-serif;
}
.settings h2{
	font-family: 'Inter', sans-serif;
	font-weight: 300;
}
.settings-picture{
	font-size: 30px;
	color: #7a7a7a;
}
.settings-picture img{
	width: 150px;
	margin: 5%;
	border-radius: 50%;
}
.settings-input{
	border: none;
	color: #7a7a7a;
}
.text-success {
	color: #15a133;
}