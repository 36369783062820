.single-post img{
	width: 100%;
	margin-bottom: 10px;
}
.single-post-title{
	font-family: 'Poiret One', cursive;
}
.single-post-title-input{
	font-family: 'Inter', sans-serif;
	border: none;
	color: #7a7a7a;
	border-bottom: 1px solid #d2d2d2;
}
single-post-title-input:focus{
	outline: none;
}
.single-post-edit{
	float: right;
	font-size: 18px;
}
.single-post-edit a{
	color: #d2d2d2;
}
.single-post-meta{
	font-family: 'Inter', sans-serif;
	font-weight: 300;
}
.single-post-meta a{
	color: #1f314f;
	text-decoration: none;
}
.single-post-meta a:hover{
	color: #1f314f;
}
.single-post-desc{
	font-family: 'Inter', sans-serif;
	text-align: justify;
}
.single-post-desc-input{
	font-family: 'Inter', sans-serif;
	color: #7a7a7a;
	border: none;
}
.single-post-desc-input:focus{
	outline: none;
}
.single-post-desc::first-letter{
	margin-left: 20px;
	font-size: 30px;
}
.edit{
	color: #50cc71;
}
.delete{
	color: #cc5450;
}
a:hover{
	color: #1f314f;
}
.edit-title-input{
	width: 100%;
	font-size: 30px;
	border: none;
	border-bottom: solid 1px #d2d2d2;
	color: #7a7a7a;
	font-family: 'Poiret One', sans-serif;
}
.edit-title-input:focus{
	outline: none;
}
.edit-desc-input{
	font-family: 'Inter', sans-serif;
	color: #7a7a7a;
	font-size: 16px;
}
.edit-desc-input:focus{
	outline: none;
}
.btn-cancel:hover{
	background-color: #cc5450;
}
.btn-update:hover{
	background-color: #50cc71;
}