.topbar{
	font-family: 'Poiret One', cursive;
	font-size: 22px;
	position: sticky;
	top: 0px;
	margin-bottom: 10px;
}
.topbar a{
	color: #fff;
}
.topbar a:link{
	text-decoration: none;
}
.topbar a:hover{
	text-decoration: none;
	color: #fff;
}
.topbar span{
	font-family: 'Poiret One', cursive;
	font-size: 28px;
}
.topbar img{
	width: 75px;
	margin: 0px 10px;
}
.nav img{
	width: 25px;
	border-radius: 50%;
	cursor: pointer;
}
.topbar-dropdown a{
	color: #000;
}
.topbar-dropdown a:link{
	text-decoration: none;
}
.topbar-dropdown a:hover{
	text-decoration: none;
	color: #000;
}