.about h2{
    font-family: 'Poiret One', cursive;
    border-bottom: solid 1px #f0f0f0;
}
.about p{
    font-family: 'Inter', sans-serif;
    text-align: justify;
}
.about h3{
    font-weight: 300;
}